<template>
  <div>
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;   position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="text-center">
            <div class="winelands-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white">
                  <h1 class="display-2"><strong>The Winelands</strong></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 text-center pt-5">
            <h2 class="switch-blue"><strong>Welcome to the Winelands</strong></h2>
            <p class="switch-blue">Orchards, vineyards and mountains – you’re spoiled for choice when it comes to picture-perfect views in the Winelands. This region is also celebrated for its world-renowned wines, award-winning restaurants and quaint towns with rich histories. There’s much to offer both residents and visitors, from outdoor activities and wine tasting routes, to popular festivals, food markets, high-quality retail shopping and boutique stores. </p>
          </div>
        </div>
      </div>
    </section>

    <section class="discover mt-5">
      <div class="container-fluid h-100">
        <div class="row h-100 justify-content-center">
          <div class="col-md-5 h-100">
            <div class="switch-bg-lgrey h-100 p-5 bg-opacity"> 
              <h2 class="switch-blue"><strong>DISCOVER</strong></h2>
              <p class="switch-blue">Close to Cape Town: While you can appreciate the safe and scenic beauty of living in the Winelands, an additional appeal of the area is that you have easy access to Cape Town’s CBD and the Cape Town International Airport. 
              Schools and universities: Access to quality education is an important factor when a family decides where to live. The Winelands and surrounding areas boast many of the country’s top-ranked schools as well as some excellent universities. </p>

              <h2 class="switch-blue mt-5"><strong>LIFESTYLE </strong></h2>
              <p class="switch-blue">Many families and retirees are leaving the hustle and bustle of city living, in favour of the tranquil pace of life in the Winelands. With wide-open spaces, secure estates and plenty of activities, it’s a desirable destination for homeowners.</p>
              <p class="switch-blue"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div>
              <h2 class="switch-blue mt-5 text-center mb-5"><strong>ACTIVITIES</strong></h2>
              <div class="row">
                <div class="col-lg-3 mb-4">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/golf.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Golf</strong></h4> 
                   <p>Choices are endless when it comes to teeing off on a prestigious golf course in the Winelands. Many verdant courses meander through vineyards, orchards and forests, with big blue skies and majestic mountain views ever-present. </p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4 ">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/equestrian.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Equestrian</strong></h4> 
                   <p>Ride through vineyards and orchards, along rivers and lakes, and explore country roads and forest paths. From beginner to advanced, horse riding in the Winelands is for everyone who wants to enjoy the outdoors from a different perspective. </p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/padel.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Padel</strong></h4> 
                   <p>It’s active, fun and social – three reasons why it’s the fastest growing sport in Africa. Padel is a great sport for players of all ages and skill level, as it can quickly and easily be learned, and doesn’t rely on sheer strength or technique.</p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/watersports.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Water sports</strong></h4> 
                   <p>With the Berg River running through our biggest estate, water sports are a popular and accessible pastime for many residents. From leisurely swims to more active canoeing or fishing outings, there’s something for everyone.</p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/dog.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Dog parks</strong></h4> 
                   <p>For your furry family members, they can enjoy some off-leash activity in a safe and dedicated dog park. While they’re socializing with their doggy friends, you also get to enjoy the scenic and open green space.</p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/jogging.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Walking/jogging trails</strong></h4> 
                   <p>From the young to the elderly, take advantage of safe trails for your daily exercise. Explore the walking paths that meander through the estates or get your heart racing with a more energetic run on a dedicated running route</p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4 ">
                  <div class="switch-bg-lblue p-5 h-100">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/court.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Court sports</strong></h4> 
                   <p>Most estates have multi-functional courts, so residents can conveniently play their choice of soccer, netball, tennis and basketball. These sports are an ideal way to keep bored teenagers active and busy.</p>
                  </div>
                </div>
                <div class="col-lg-3 mb-4 ">
                  <div class="switch-bg-lblue p-5 h-100 text-start">
                    <video width="100%" height="180" controls>
                      <source src="/images/videos/bike.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                   <h4 class="switch-blue"><strong>Mountain biking</strong></h4> 
                   <p>Farmlands, vineyards and mountains form the perfect backdrop for your more adventurous side. Whatever your level of experience, you can ride and relish some of the best mountain biking trails in the country.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>

<style>
.discover {
    background: url("/images/winelands-discover.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
</style>